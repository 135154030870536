<template>
  <settings-template
    :config="config"
    :search="$attrs.search"
  >
    <!-- User Info
    <template #icon0>
      <v-icon size="60" color="blue">fas fa-user-circle</v-icon>
    </template> -->
    <template #slot0>
      <v-card>
        <v-card-title class="blue"><h3 class="white--text">User Info</h3></v-card-title>
        <v-container>
          <v-layout v-if="user.id" row wrap :class="{ 'justify-start': $vuetify.breakpoint.mdAndUp, 'justify-center': $vuetify.breakpoint.smAndDown }">
            <!-- First Name -->
            <v-flex xs6 md4>
              <v-text-field
                v-model="user.firstName"
                :readonly="true"
                solo
                hint="First Name"
                persistent-hint
              />
            </v-flex>
            <!-- Last Name -->
            <v-flex xs6 md4>
              <v-text-field
                v-model="user.lastName"
                :readonly="true"
                solo
                hint="Last Name"
                persistent-hint
              />
            </v-flex>
            <!-- Initials -->
            <v-flex xs3>
              <v-text-field
                v-model="user.initials"
                :readonly="true"
                solo
                hint="Initials"
                persistent-hint
              />
            </v-flex>
            <!-- Email Address -->
            <v-flex xs12 lg6>
              <v-text-field
                v-model="user.email"
                :readonly="true"
                solo
                hint="Email"
                persistent-hint
              />
            </v-flex>
            <v-flex>
              <v-btn
                flat
                color="info"
                @click="goToAuthManagement()"
              >
                Account Management
                <v-icon right small>fal fa-external-link</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-progress-linear v-else indeterminate/>
        </v-container>
      </v-card>
    </template>
    <!-- Employee Profile -->
    <template #slot1>
      <v-card>
        <v-card-title class="orange"><h3 class="white--text">Employee Profile</h3></v-card-title>
        <v-container>
          <v-layout v-if="profile.id" row wrap :class="{ 'justify-start': $vuetify.breakpoint.mdAndUp, 'justify-center': $vuetify.breakpoint.smAndDown }" align-center>
            <v-flex shrink>
              <v-text-field
                v-model="profile.jobTitle"
                solo
                readonly
                hint="Job Title"
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex shrink>
              <v-text-field
                v-model="profile.manager"
                solo
                readonly
                hint="Manager"
                persistent-hint
              />
            </v-flex>
            <v-flex shrink>
              <v-text-field
                v-model="profile.department"
                solo
                readonly
                hint="Department"
                persistent-hint
              />
            </v-flex>
            <!-- <v-flex xs10 md4>
              <v-text-field
                v-model="profile.commission"
                solo
                readonly
                hint="Commission"
                persistent-hint
              />
            </v-flex>
            <v-flex xs10 md4>
              <v-text-field
                v-model="profile.lastReview"
                solo
                readonly
                hint="Last Review"
                persistent-hint
              />
            </v-flex>
            <v-flex xs10 md4>
              <v-text-field
                v-model="profile.wage"
                solo
                readonly
                hint="Hourly Wage"
                persistent-hint
              />
            </v-flex>
            <v-flex xs10 md4>
              <v-text-field
                v-model="profile.hireDate"
                solo
                readonly
                hint="Hire Date"
                persistent-hint
              />
            </v-flex>
            <v-flex xs10 md4>
              <v-text-field
                v-model="profile.upcomingReview"
                solo
                readonly
                hint="Upcoming Review"
                persistent-hint
              />
            </v-flex> -->
          </v-layout>
          <v-progress-linear color="purple" v-else indeterminate/>
        </v-container>
      </v-card>
    </template>
    <!-- Preferences -->
    <template #slot2>
      <v-card>
        <v-card-title :style="{ 'background-color': profileColor }"><h3 :class="profileColorTextColor">Preferences</h3></v-card-title>
        <v-container>
          <v-layout v-if="profile.id" row wrap :class="{ 'justify-start': $vuetify.breakpoint.mdAndUp, 'justify-center': $vuetify.breakpoint.smAndDown }" align-center>
            <!-- Default Tenant -->
            <v-flex shrink>
              <v-select
                v-model="defaultTenant"
                :items="tenants"
                label="Default Tenant"
                clearable
                @change="updateTenant"
                @click:clear="clearTenant"
              />
            </v-flex>
            <!-- Show Column Borders -->
            <v-flex shrink>
              <v-checkbox
                v-model="showColumnBorders"
                label="Show Column Borders in Grids"
              ></v-checkbox>
            </v-flex>
            <!-- Profile Color -->
            <v-flex shrink>
              <v-dialog
                v-model="dialog"
                persistent
                max-width="500px"
              >
                <template #activator="{ on }">
                  <v-btn
                    small
                    color="info"
                    v-on="on"
                  >
                    Change Color
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="purple">
                    <h3 class="white--text">Profile Color Change</h3>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout justify-center align-center>
                        <swatch-picker v-model="color"/>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <t-alert
                      :message="message"
                      :type="type"
                    />
                    <v-spacer></v-spacer>
                    <cancel-button class="pr-2" :success="succeeded" @click="cancelColorChange"/>
                    <submit-button :disabled="!canChangeColor || succeeded" :loading="saving" @click="changeColor"/>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-progress-linear color="purple" v-else indeterminate/>
        </v-container>
      </v-card>
    </template>

    <!-- Printers -->
    <template #slot3>
      <print-management/>
    </template>
  </settings-template>
</template>

<script>
import { Swatches } from 'vue-color'
import { CHANGE_PROFILE_COLOR, UpdateProfileSettings } from '@/api/graphql/Constants/Profile'
import tAlert from '@/components/notifications/tAlert'
import settingsTemplate from '@/views/settings/settingsTemplate'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import PrintManagement from '@/components/settings/PrintManagement.vue'
export default {
  name: 'accountManagement',
  components: {
    'swatch-picker': Swatches,
    't-alert': tAlert,
    'settings-template': settingsTemplate,
    'print-management': PrintManagement,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  computed: {
    user () {
      return this.$store.state.profile.user
    },

    tenants () {
      return this.$store.getters['auth/tenantStrings']
    },

    profileColor () {
      if (this.profile.color !== '#FFFFFF') return this.profile.color
      else return 'purple'
    },

    profileColorTextColor () {
      const color = this.profileColor
      if (color) {
        const c = color.substring(1) // strip #
        const rgb = parseInt(c, 16) // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff // extract red
        const g = (rgb >> 8) & 0xff // extract green
        const b = (rgb >> 0) & 0xff // extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

        if (luma < 150) {
          return 'white--text'
        }
        return 'black--text'
      } else return ''
    },

    canChangeColor () {
      return this.originalColor !== this.color
    },

    profile: function () {
      return this.user.profile
    },

    settings: function () {
      return this.$store.getters['data/settings']
    },

    showColumnBorders: {
      get () {
        return this.settings.showColumnBorders
      },
      set (value) {
        this.$store.dispatch('data/updateUserSettingsKey', { key: 'showColumnBorders', value: value }).then(() => {
          try {
            UpdateProfileSettings(this.user.profile.id, this.settings)
          } catch (error) {
            this.$store.dispatch('notifications/createSnackbar', {
              message: error.message,
              color: 'error'
            })
          }
        })
      }
    },

    canSave: function () {
      return this.password === this.passwordConfirm && this.password.length > 12 && this.oldPassword.length !== 0
    }
  },
  watch: {
    dialog: function (value) {
      this.message = ''
      this.type = 'info'
      this.succeeded = false
      this.saving = false
    },

    profileColor: function (value) {
      if (value) {
        this.config.icons.icon2.color = value
      }
    }
  },
  data () {
    return {
      config: {
        icons: {
          icon0: {
            icon: 'fas fa-user-circle',
            color: 'blue'
          },
          icon1: {
            icon: 'fas fa-user-tie',
            color: 'orange'
          },
          icon2: {
            icon: 'fas fa-user-cog',
            color: ''
          },
          icon3: {
            color: 'grey',
            icon: 'fas fa-print'
          }
        },
        slot0: 'User Info',
        slot1: 'Employee Profile',
        slot2: 'Preferences',
        slot3: 'Print Management'
      },

      message: '',
      type: 'info',

      defaultTenant: '',

      dialog: false,
      color: '',
      originalColor: '',

      succeeded: false,
      saving: false,

      // password data
      oldPassword: '',
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
      rules: {
        old: p => !!p || 'Enter your current password',
        required: p => !!p || 'Enter a password',
        min: p => p.length >= 12 || 'Must be at least 12 characters',
        matchOne: p => p === this.password || 'New passwords must match'
      },
      passwordAlertMessage: '',
      passwordAlertType: undefined
    }
  },
  methods: {
    cancel () {
      this.$refs.changePassword.reset()
      this.oldPassword = ''
      this.password = ''
      this.passwordConfirm = ''
    },

    goToAuthManagement () {
      const url = 'https://auth.traqsys.com/auth/realms/TRAQSYS/account'
      window.open(url)
    },

    clearTenant () {
      this.defaultTenant = ''
      locStore.remove('defaultTenant')
    },

    updateTenant (tenant) {
      if (tenant) {
        this.defaultTenant = tenant
        locStore.set(LOCAL_STORAGE_KEYS.defaultTenant, tenant.toLowerCase())
      }
    },

    cancelColorChange () {
      this.dialog = false
      this.setColors()
    },

    resultHandler ({ message, type }) {
      this.message = message
      this.type = type
      if (type === 'success') {
        this.succeeded = true
        this.saving = false
      }
    },

    setColors (color = this.profileColor) {
      this.color = color
      this.originalColor = color
    },

    changeColor () {
      this.saving = true
      if (this.color?.hex) {
        this.$apollo.mutate({
          mutation: CHANGE_PROFILE_COLOR,
          variables: {
            id: this.user.profile.id,
            color: this.color.hex
          }
        })
          .then(({ data: { profile } }) => {
            this.$store.dispatch('profile/updateProfile', { key: 'color', value: profile.color })
            this.config.icons.icon2.color = profile.color
            this.saving = false
            this.succeeded = true
            this.message = 'Successfully changed color'
            this.type = 'success'
            this.setColors(profile.color)
          })
      }
    }
  },
  created () {
    this.config.icons.icon2.color = this.profileColor
  },
  mounted () {
    this.setColors()
    const tenant = locStore.get('defaultTenant')
    if (tenant) {
      this.defaultTenant = tenant
    }
  }
}
</script>
